const SvgNotificationFocus = () => (
  <span>
    <svg width="28" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3 3C12.0568 3 9.90541 3.87912 8.31921 5.44396C6.73301 7.0088 5.84189 9.13118 5.84189 11.3442V15.5497C5.84206 15.7346 5.79862 15.917 5.71501 16.0825L3.64036 20.1748C3.53902 20.3747 3.49116 20.5968 3.50134 20.8201C3.51152 21.0435 3.5794 21.2605 3.69853 21.4506C3.81766 21.6408 3.98409 21.7977 4.182 21.9065C4.37991 22.0154 4.60275 22.0725 4.82933 22.0725H23.7707C23.9973 22.0725 24.2201 22.0154 24.418 21.9065C24.6159 21.7977 24.7823 21.6408 24.9015 21.4506C25.0206 21.2605 25.0885 21.0435 25.0987 20.8201C25.1088 20.5968 25.061 20.3747 24.9596 20.1748L22.8862 16.0825C22.8022 15.9171 22.7583 15.7347 22.7581 15.5497V11.3442C22.7581 9.13118 21.867 7.0088 20.2808 5.44396C18.6946 3.87912 16.5432 3 14.3 3ZM14.3 24.6C13.5501 24.6002 12.8186 24.3711 12.2062 23.9441C11.5938 23.5171 11.1306 22.9134 10.8805 22.2159H17.7195C17.4694 22.9134 17.0062 23.5171 16.3938 23.9441C15.7814 24.3711 15.0499 24.6002 14.3 24.6Z"
        fill="white"
      />
    </svg>
  </span>
);

export default SvgNotificationFocus;
