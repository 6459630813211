import React from 'react';
import { ConfigProvider, theme } from 'antd';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { RootState, useAppDispatch } from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RoutesApp from './routes';
import { ToastContainer } from 'react-toastify';
import './styles/variables.scss';
const App: React.FC = () => {
  const { locale, isDarkMode } = useSelector((state: RootState) => state.setting);
  const dispatch = useAppDispatch();
  // const currentAppLocale = (AppLocale as any)[locale.locale];
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  // useEffect(() => {
  //   window.addEventListener('resize', () => {
  //     dispatch(updateWindowWidth(window.innerWidth));
  //   });
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          components: {
            Checkbox: {
              colorPrimaryHover: '#514b4d',
              colorBorder: '#514b4d',
              colorPrimary: '#514b4d',
            },
          },
        }}
        // locale={currentAppLocale.antd}
      >
        <IntlProvider locale={null} messages={null}>
          <RoutesApp />
        </IntlProvider>
      </ConfigProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
};

export default App;
