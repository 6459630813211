const SvgReduction = () => (
  <span>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.625 3.99988L4 9.62488L9.625 15.2499"
        stroke="white"
        stroke-width="2.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.7188 3.99988L10.0938 9.62488L15.7188 15.2499"
        stroke="white"
        stroke-width="2.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
);

export default SvgReduction;
