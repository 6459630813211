const SvgNotification = () => (
  <span>
    <svg width="28" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.62 20.28H23.5726C24.4162 20.28 25.1 19.5962 25.1 18.7527C25.1 18.3476 24.9391 17.9591 24.6526 17.6727L23.5726 16.5926C23.1676 16.1876 22.94 15.6382 22.94 15.0653L22.94 11.6083C22.94 6.85408 19.0859 3 14.3317 3C9.56732 3 5.70907 6.86985 5.72337 11.6342L5.73359 15.0394C5.73536 15.6275 5.49723 16.191 5.0742 16.5995L3.96293 17.6729C3.66708 17.9586 3.5 18.3523 3.5 18.7636C3.5 19.6011 4.17892 20.28 5.0164 20.28H9.98C9.98 22.6659 11.9141 24.6 14.3 24.6C16.6859 24.6 18.62 22.6659 18.62 20.28ZM22.4271 17.7382L23.349 18.66H5.27278L6.19965 17.7648C6.93995 17.0498 7.35667 16.0638 7.35358 15.0346L7.34336 11.6293C7.33175 7.76158 10.4639 4.62 14.3317 4.62C18.1912 4.62 21.32 7.74878 21.32 11.6083L21.32 15.0653C21.32 16.0678 21.7182 17.0293 22.4271 17.7382ZM17 20.28H11.6C11.6 21.7712 12.8088 22.98 14.3 22.98C15.7912 22.98 17 21.7712 17 20.28Z"
        fill="white"
      />
    </svg>
  </span>
);

export default SvgNotification;
