const SvgLogoHome = () => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_35323_99923)">
      <g clip-path="url(#clip1_35323_99923)">
        <path
          d="M34.0841 0H14.726C11.74 0 9.31934 2.42063 9.31934 5.40664V24.7648C9.31934 27.7508 11.74 30.1714 14.726 30.1714H34.0841C37.0701 30.1714 39.4908 27.7508 39.4908 24.7648V5.40664C39.4908 2.42063 37.0701 0 34.0841 0Z"
          fill="white"
        />
        <path
          d="M31.5341 15.6417C31.1205 14.8508 30.5236 14.1792 29.7433 13.6249C29.6004 13.5247 29.5791 13.3222 29.7028 13.2006C30.1782 12.7423 30.5193 12.2946 30.724 11.8554C31.0416 11.1902 31.2015 10.2671 31.2015 9.0817C31.2015 7.16507 30.4831 5.66417 29.0483 4.57474C27.7478 3.59191 26.0912 3.10156 24.0787 3.10156C22.0661 3.10156 20.3669 3.76034 19.0366 5.08002C17.8129 6.30376 17.2031 7.75136 17.2031 9.42707V27.8258C17.2031 28.0902 17.557 28.2139 17.7425 28.0135L20.5439 24.9413H25.5796C27.4962 24.9413 29.0653 24.3444 30.2891 23.1505C31.5128 21.9758 32.1225 20.3533 32.1225 18.2832C32.1225 17.311 31.9243 16.4305 31.5299 15.6396L31.5341 15.6417Z"
          fill="url(#paint0_linear_35323_99923)"
        />
        <path
          d="M20.7422 15.2265C21.3497 15.2265 21.8423 14.734 21.8423 14.1265C21.8423 13.5189 21.3497 13.0264 20.7422 13.0264C20.1346 13.0264 19.6421 13.5189 19.6421 14.1265C19.6421 14.734 20.1346 15.2265 20.7422 15.2265Z"
          fill="white"
        />
        <path
          d="M23.7056 15.2265C24.3131 15.2265 24.8056 14.734 24.8056 14.1265C24.8056 13.5189 24.3131 13.0264 23.7056 13.0264C23.098 13.0264 22.6055 13.5189 22.6055 14.1265C22.6055 14.734 23.098 15.2265 23.7056 15.2265Z"
          fill="white"
        />
        <path
          d="M26.6689 15.2265C27.2765 15.2265 27.769 14.734 27.769 14.1265C27.769 13.5189 27.2765 13.0264 26.6689 13.0264C26.0614 13.0264 25.5688 13.5189 25.5688 14.1265C25.5688 14.734 26.0614 15.2265 26.6689 15.2265Z"
          fill="white"
        />
      </g>
    </g>
    <g clip-path="url(#clip2_35323_99923)">
      <path
        d="M14.8286 46.241H13.4805C13.4083 46.241 13.3508 46.1841 13.3508 46.1127L13.3442 42.2368C13.3442 41.7628 13.1571 41.3684 12.7827 41.0535C12.4083 40.7516 11.9141 40.6007 11.2983 40.6007C10.814 40.6007 10.3854 40.7435 10.011 41.0276C9.51351 41.4057 9.26557 41.956 9.26557 42.6782C9.26557 43.3405 9.47574 43.8826 9.89445 44.3014C10.277 44.6795 10.745 44.8694 11.2967 44.8694C11.6152 44.8694 11.919 44.7769 12.2031 44.5919C12.4297 44.4442 12.6875 44.204 12.9764 43.868C13.0553 43.7771 13.2047 43.8323 13.2047 43.9524V45.5966C13.2047 45.6404 13.1817 45.6826 13.1439 45.707C12.7745 45.9374 12.5101 46.09 12.3508 46.1614C12.0126 46.2961 11.6251 46.3643 11.1867 46.3643C10.2705 46.3643 9.47574 46.0397 8.80582 45.3905C8.062 44.6828 7.69092 43.7787 7.69092 42.6782C7.69092 41.6541 8.00125 40.8182 8.62192 40.1689C9.29184 39.4613 10.2179 39.1074 11.4001 39.1074C12.5824 39.1074 13.4148 39.4304 14.0618 40.0748C14.6595 40.6753 14.9583 41.4236 14.9583 42.3212V46.116C14.9583 46.1874 14.8992 46.2442 14.8286 46.2442V46.241Z"
        fill="white"
      />
      <path
        d="M21.5116 39.1572C21.5839 39.1572 21.643 39.2157 21.6413 39.2871L21.6282 41.6519C21.6233 42.3466 21.4591 42.9391 21.1356 43.4309C20.7777 43.9665 20.2457 44.3463 19.5445 44.567C19.4904 44.5849 19.4526 44.6336 19.4526 44.6888V47.5974C19.4526 47.6688 19.3935 47.7256 19.3229 47.7256H17.9682C17.896 47.7256 17.8385 47.6672 17.8385 47.5974V44.6888C17.8385 44.632 17.8008 44.5833 17.7466 44.567C17.1292 44.3723 16.6465 44.0801 16.2984 43.6889C15.8879 43.2377 15.6826 42.6648 15.6826 41.9701V39.2871C15.6826 39.2157 15.7417 39.1589 15.8123 39.1589H17.139C17.2113 39.1589 17.2688 39.2173 17.2688 39.2871V41.6519C17.2688 42.0902 17.3624 42.4326 17.5495 42.6794C17.7729 42.9861 18.1357 43.1387 18.6382 43.1387C19.0799 43.1387 19.423 42.9942 19.6644 42.7053C19.9058 42.431 20.0273 42.0869 20.0273 41.6779V39.2855C20.0273 39.214 20.0864 39.1572 20.157 39.1572H21.51H21.5116Z"
        fill="white"
      />
      <path
        d="M6.78793 41.8844C6.59253 41.5144 6.31011 41.1995 5.93902 40.9398C5.8717 40.8927 5.86185 40.7986 5.92096 40.7418C6.14592 40.5275 6.30683 40.3165 6.40534 40.112C6.55641 39.8004 6.63194 39.3686 6.63194 38.8135C6.63194 37.9159 6.29205 37.2131 5.61227 36.7035C4.99653 36.2442 4.21167 36.0137 3.25768 36.0137C2.30369 36.0137 1.49912 36.3221 0.868602 36.9405C0.288984 37.5134 0 38.1919 0 38.9758V47.5929C0 47.7162 0.167481 47.7747 0.256148 47.6805L1.61899 46.2035C1.63048 46.1905 1.63869 46.1775 1.64361 46.1646C1.64361 46.1613 1.64526 46.1581 1.6469 46.1548C1.64854 46.1467 1.65183 46.1402 1.65183 46.1321C1.65183 46.1256 1.65511 46.1191 1.65511 46.1126L1.64198 39.1592C1.64198 38.7178 1.79304 38.338 2.09352 38.0231C2.38086 37.7212 2.78643 37.5702 3.31186 37.5702C3.7946 37.5702 4.19689 37.679 4.51707 37.8948C4.82248 38.1205 4.97518 38.4548 4.97518 38.8963C4.97518 39.2566 4.87666 39.5569 4.68127 39.7955C4.43005 40.112 4.04254 40.2694 3.51875 40.2694H1.95888C1.88663 40.2694 1.82916 40.3279 1.82916 40.3977V41.7091C1.82916 41.7806 1.88827 41.8374 1.95888 41.8374H3.73057C4.21003 41.8374 4.59261 41.9364 4.87995 42.1344C5.23626 42.3827 5.41359 42.7528 5.41359 43.2446C5.41359 43.7364 5.25432 44.1065 4.93414 44.3532C4.66485 44.5658 4.30854 44.6713 3.86685 44.6713H1.95723C1.88499 44.6713 1.82751 44.7297 1.82751 44.7995V46.111C1.82751 46.1824 1.88663 46.2392 1.95723 46.2392H3.96865C4.87667 46.2392 5.62048 45.96 6.2001 45.4001C6.77971 44.8498 7.0687 44.0902 7.0687 43.1196C7.0687 42.6635 6.97511 42.2513 6.78793 41.8812V41.8844Z"
        fill="white"
      />
      <path
        d="M36.6127 46.193H35.2077C35.1324 46.193 35.0725 46.1359 35.0725 46.0641L35.0657 42.1686C35.0657 41.6922 34.8706 41.2958 34.4804 40.9794C34.0903 40.6759 33.5752 40.5242 32.9335 40.5242C32.4286 40.5242 31.982 40.6678 31.5918 40.9533C31.0733 41.3333 30.8149 41.8864 30.8149 42.6123C30.8149 43.2778 31.034 43.8227 31.4703 44.2436C31.8691 44.6237 32.3568 44.8145 32.9317 44.8145C33.2637 44.8145 33.5803 44.7215 33.8764 44.5356C34.1125 44.3871 34.3812 44.1457 34.6824 43.808C34.7645 43.7167 34.9202 43.7721 34.9202 43.8928V45.5453C34.9202 45.5894 34.8963 45.6318 34.8569 45.6563C34.4719 45.8879 34.1964 46.0413 34.0304 46.113C33.6779 46.2484 33.274 46.3169 32.8171 46.3169C31.8622 46.3169 31.034 45.9907 30.3358 45.3382C29.5606 44.6269 29.1738 43.7183 29.1738 42.6123C29.1738 41.5829 29.4973 40.7428 30.1441 40.0903C30.8423 39.3791 31.8075 39.0234 33.0396 39.0234C34.2717 39.0234 35.1393 39.3481 35.8135 39.9957C36.4364 40.5993 36.7479 41.3513 36.7479 42.2534V46.0674C36.7479 46.1391 36.6863 46.1962 36.6127 46.1962V46.193Z"
        fill="white"
      />
      <path
        d="M42.171 46.2098C42.171 46.2683 42.1234 46.3137 42.0643 46.3137L40.726 46.2943C39.621 46.2943 38.7343 45.8933 38.066 45.0915C37.4864 44.4033 37.1958 43.6389 37.1958 42.7965C37.1958 41.8258 37.4996 41.0078 38.1071 40.3375C38.7573 39.6087 39.6292 39.2451 40.726 39.2451H42.0643C42.1217 39.2451 42.1693 39.2922 42.1693 39.349V40.6653C42.1693 40.7221 42.1217 40.7692 42.0643 40.7692H40.905C40.4354 40.7692 40.0069 40.9185 39.6194 41.2204C39.1169 41.6035 38.8657 42.1375 38.8657 42.8257C38.8657 43.3045 39.0364 43.7346 39.3763 44.116C39.7819 44.5624 40.3106 44.7848 40.9658 44.7848H42.0626C42.1201 44.7848 42.1677 44.8318 42.1677 44.8886V46.2098H42.171Z"
        fill="white"
      />
      <path
        d="M48.7058 39.245C48.7879 39.245 48.8372 39.3359 48.7945 39.4041C48.3495 40.1296 48.031 40.6458 47.8421 40.9525C47.5466 41.4248 47.3003 41.7706 47.1 41.9897C47.0507 42.0432 47.0753 42.126 47.1443 42.152C47.5417 42.3062 47.8421 42.5399 48.0425 42.8564C48.2411 43.1616 48.3397 43.5332 48.3397 43.9699L48.3528 46.2081C48.3528 46.2649 48.3068 46.312 48.2477 46.312H46.8553C46.7978 46.312 46.7502 46.2649 46.7502 46.2081V44.3513C46.7502 43.9877 46.6813 43.7069 46.5433 43.5105C46.4005 43.3239 46.1526 43.2314 45.7979 43.2314L44.7125 43.2443C44.6551 43.2443 44.6091 43.2914 44.6091 43.3482V46.2081C44.6091 46.2649 44.5615 46.312 44.504 46.312H43.11C43.0525 46.312 43.0049 46.2649 43.0049 46.2081V36.2601C43.0049 36.2033 43.0525 36.1562 43.11 36.1562H44.504C44.5615 36.1562 44.6091 36.2033 44.6091 36.2601V41.6196C44.6091 41.6764 44.6567 41.7235 44.7142 41.7235H45.0524C45.3233 41.691 45.5319 41.6115 45.6747 41.4849C45.8045 41.3615 45.9884 41.0775 46.2281 40.6311L47.0261 39.2953C47.0441 39.2645 47.0786 39.245 47.1164 39.245H48.7075H48.7058Z"
        fill="white"
      />
      <path
        d="M29.1739 39.2684C29.1739 40.234 28.8585 41.0415 28.2293 41.6907C27.6635 42.2812 27.0343 42.5764 26.3417 42.5764H24.3859C24.329 42.5764 24.2818 42.5291 24.2818 42.472V41.0871C24.2818 41.0301 24.329 40.9828 24.3859 40.9828H25.8897C26.4913 40.9828 26.9319 40.7576 27.2099 40.3107C27.4017 39.9991 27.4977 39.6517 27.4977 39.2667C27.4977 38.8459 27.3936 38.4821 27.1823 38.1755C26.8815 37.7449 26.4181 37.5312 25.7938 37.5312C25.1695 37.5312 24.711 37.7644 24.4184 38.2309C24.2135 38.5702 24.1111 38.9764 24.1111 39.4527V46.2123C24.1111 46.2694 24.064 46.3167 24.0071 46.3167H22.5455C22.4886 46.3167 22.4414 46.2694 22.4414 46.2123V39.425C22.4414 38.3957 22.7796 37.5475 23.4543 36.8787C24.0883 36.2523 24.8687 35.9375 25.7938 35.9375C26.6653 35.9375 27.4213 36.2099 28.0651 36.7547C28.8032 37.3811 29.1739 38.2195 29.1739 39.2667V39.2684Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_35323_99923"
        x1="24.6628"
        y1="3.10156"
        x2="24.6628"
        y2="28.1127"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E65925" />
        <stop offset="0.19" stop-color="#E85F23" />
        <stop offset="1" stop-color="#F47920" />
      </linearGradient>
      <clipPath id="clip0_35323_99923">
        <rect width="30.1714" height="30.1714" fill="white" transform="translate(9.31934)" />
      </clipPath>
      <clipPath id="clip1_35323_99923">
        <rect width="30.1714" height="30.1714" fill="white" transform="translate(9.31934)" />
      </clipPath>
      <clipPath id="clip2_35323_99923">
        <rect width="48.8104" height="12.3429" fill="white" transform="translate(0 35.6572)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLogoHome;
