import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SuspenseWrapper } from '../../components/loading/SuspenseWrap';
import AuthRoute from '../../components/router/auth.router';
import { ACTION, USER_ROUTE_NAME, USER_ROUTE_PATH } from '../../constants/router';
import Ship2BuyerRequest from './shipBuyerRequest/request';
import '../../styles/variables.scss';
// import ForgotPasswordCustomer from './auth/ForgotPassword';
// import ResetPassword from './auth/ResetPassword';
// import ResetPasswordWrapper from './auth/ResetPassword';
// import SignUpCustomer from './auth/SignUpCustomer';
// import StatusAccountComplete from './auth/statusAccountComplete';
// import SystemNotification from './system-notification';
// import ChatRouter from '../../components/router/chat.router';
// import StatusAccountFail from './auth/statusAccountCompleteFail';
// import OTPCustomerWrapper from '../../components/OTPCustomer/otpCustomer';
// import Settings from './setting';
// import Profile from './profile/Profile';
// import CardInput from './auth/CardInput';
// import EditCard from './cardEdit';
// import EbayManagement from './ebay-management';
// import PlanSetting from './planSetting';
// import SelectPlan from './planSetting/selectPlan';
// import PlanDetail from './planSetting/planDetail';
// import PageNotFound from './pageNotFound';
// import Verify from '../../components/verify';
// import EbayReturn from './return';
// import StatusAccountMemberFail from './auth/statusAccountCompleteFail/statusAccountMember';
// import QuitMemberShip from './quitMemberShip';
// import MemberManagement from './memberManagement';

const Customer = React.lazy(() => import('.'));
const CardInput = React.lazy(() => import('./auth/CardInput'));
const Profile = React.lazy(() => import('./profile/Profile'));
// const Settings = React.lazy(() => import('./setting'));
const OTPCustomerWrapper = React.lazy(() => import('../../components/OTPCustomer/otpCustomer'));
const StatusAccountFail = React.lazy(() => import('./auth/statusAccountCompleteFail'));
const ChatRouter = React.lazy(() => import('../../components/router/chat.router'));
const SystemNotification = React.lazy(() => import('./system-notification'));
const StatusAccountComplete = React.lazy(() => import('./auth/statusAccountComplete'));
const SignUpCustomer = React.lazy(() => import('./auth/SignUpCustomer'));
const ResetPasswordWrapper = React.lazy(() => import('./auth/ResetPassword'));
const ResetPassword = React.lazy(() => import('./auth/ResetPassword'));
const ForgotPasswordCustomer = React.lazy(() => import('./auth/ForgotPassword'));
const StatusAccountMemberFail = React.lazy(() => import('./auth/statusAccountCompleteFail/statusAccountMember'));
const QuitMemberShip = React.lazy(() => import('./quitMemberShip'));
const MemberManagement = React.lazy(() => import('./memberManagement'));

const SignInCustomer = React.lazy(() => import('./auth/SignInCustomer'));
const EditCard = React.lazy(() => import('./cardEdit'));
const PlanSetting = React.lazy(() => import('./planSetting'));
const SelectPlan = React.lazy(() => import('./planSetting/selectPlan'));
const PlanDetail = React.lazy(() => import('./planSetting/planDetail'));
const Verify = React.lazy(() => import('../../components/verify'));

const EbayManagement = React.lazy(() => import('./ebay-management'));
const EbayReturn = React.lazy(() => import('./return'));
// const ReturnList = React.lazy(() => import('./return/list'));
// const ReturnDetail = React.lazy(() => import('./return/actions'));
// const StorageRequest = React.lazy(() => import('./return/storage-request'));
const ItemMangement = React.lazy(() => import('./itemMangement'));
const ItemDetail = React.lazy(() => import('./itemMangement/itemDetail'));
const NewItemDetail = React.lazy(() => import('./itemMangement/newItemDetail'));

// const MemberManagement = React.lazy(() => import('./memberManagement'));
const ShipBuyerRequest = React.lazy(() => import('./shipBuyerRequest'));

const ChatSupport = React.lazy(() => import('./chatSupport'));

const PaymentDetail = React.lazy(() => import('./paymentDetail'));

const PrivacyPolicy = React.lazy(() => import('./privacyPolicy'));
const SpecifiedTransactionArt = React.lazy(() => import('./specifiedTransactionArt'));
const TermsOfService = React.lazy(() => import('./termsOfService'));
const PageNotFound = React.lazy(() => import('./pageNotFound'));
const NotifySetting = React.lazy(() => import('./notifySetting'));
const PaymentListHistory = React.lazy(() => import('./paymentListHistory'));
const PaymentHistoryDetail = React.lazy(() => import('./paymentListHistory/detail'));
const Support = React.lazy(() => import('./support'));
const ShippingLabelHistory = React.lazy(() => import('./shippingLabelHistory'));
const Fedex = React.lazy(() => import('./fedex'));
const ChatWork = React.lazy(() => import('./chatwork'));
const TaskManagement = React.lazy(() => import('./taskManagement'));

export const CustomerRoutes = () => (
  <Routes>
    {/* PrivacyPolicy - SpecifiedTransactionArt - TermsOfService*/}
    <Route path={USER_ROUTE_NAME.PRIVACY_POLICY} element={<SuspenseWrapper component={<PrivacyPolicy />} />} />
    <Route
      path={USER_ROUTE_NAME.SPECIFIED_TRANSACTION_ART}
      element={<SuspenseWrapper component={<SpecifiedTransactionArt />} />}
    />
    <Route path={USER_ROUTE_NAME.TERMS_OF_SERVICE} element={<SuspenseWrapper component={<TermsOfService />} />} />
    <Route path={USER_ROUTE_NAME.PLAN_LIST}>
      <Route path={''} element={<SuspenseWrapper component={<SelectPlan />} />} />
      <Route path={USER_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<PlanDetail />} />} />
    </Route>

    <Route path={USER_ROUTE_NAME.AUTH}>
      {/* SIGNIN */}
      <Route path={USER_ROUTE_NAME.SIGNIN} element={<SuspenseWrapper component={<SignInCustomer />} />} />

      <Route path={USER_ROUTE_NAME.VERIFY}>
        {' '}
        <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<Verify />} />} />
        <Route path={USER_ROUTE_NAME.FAIL} element={<SuspenseWrapper component={<StatusAccountMemberFail />} />} />
      </Route>

      {/* SIGNUP */}
      <Route path={USER_ROUTE_NAME.SIGNUP}>
        <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<SignUpCustomer />} />} />
        <Route path={USER_ROUTE_NAME.RESET_PASSWORD} element={<SuspenseWrapper component={<ResetPassword />} />} />
        <Route path={USER_ROUTE_PATH.CARD_INPUT} element={<SuspenseWrapper component={<CardInput />} />} />
        <Route path={USER_ROUTE_PATH.SIGNUP_OTP} element={<SuspenseWrapper component={<OTPCustomerWrapper />} />} />
        {/*<Route path={USER_ROUTE_PATH.SELECT_PLAN_SIGNUP} element={<SuspenseWrapper component={<SelectPlan />} />} />*/}
        <Route path={USER_ROUTE_PATH.SELECT_PLAN_SIGNUP}>
          <Route path={USER_ROUTE_PATH.SELECT_PLAN_SIGNUP} element={<SuspenseWrapper component={<SelectPlan />} />} />
          <Route path={USER_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<PlanDetail />} />} />
        </Route>

        {/*<Route path={USER_ROUTE_PATH.DETAIL_PLAN} element={<SuspenseWrapper component={<PlanDetail />} />} />*/}
        <Route path={USER_ROUTE_NAME.SUCCESS} element={<SuspenseWrapper component={<StatusAccountComplete />} />} />
        <Route path={USER_ROUTE_NAME.FAIL} element={<SuspenseWrapper component={<StatusAccountFail />} />} />
      </Route>

      {/* FORGOT */}
      <Route path={USER_ROUTE_NAME.FORGOT}>
        <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<ForgotPasswordCustomer />} />} />
        <Route
          path={USER_ROUTE_NAME.RESET_PASSWORD}
          element={<SuspenseWrapper component={<ResetPasswordWrapper />} />}
        />
        <Route path={USER_ROUTE_PATH.FORGOT_OTP} element={<SuspenseWrapper component={<OTPCustomerWrapper />} />} />{' '}
        {/* Pass the screen prop */}
        <Route path={USER_ROUTE_NAME.SUCCESS} element={<SuspenseWrapper component={<StatusAccountComplete />} />} />
        <Route path={USER_ROUTE_NAME.FAIL} element={<SuspenseWrapper component={<StatusAccountFail />} />} />
      </Route>
    </Route>

    <Route element={<AuthRoute />}>
      <Route element={<SuspenseWrapper component={<ChatRouter />} />}>
        <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<Customer />} />}>
          {/* RETURN_MANAGEMENT */}
          <Route path={USER_ROUTE_NAME.RETURN_MANAGEMENT}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<EbayReturn />} />} />
            {/* <Route path={USER_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<ReturnDetail />} />} /> */}
            {/* <Route path={USER_ROUTE_NAME.RETURN_STORAGE} element={<SuspenseWrapper component={<StorageRequest />} />} /> */}
            {/* <Route path={USER_ROUTE_NAME.RETURN_ITEM} element={<SuspenseWrapper component={<ItemMangement />} />} /> */}
          </Route>

          {/* RETURN_ITEM */}
          <Route path={USER_ROUTE_NAME.RETURN_ITEM}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<ItemMangement />} />} />
            <Route path={USER_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<NewItemDetail />} />} />
          </Route>

          {/* RETURN_ITEM */}
          <Route path={USER_ROUTE_NAME.PAYMENT_LIST_HISTORY}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<PaymentListHistory />} />} />
            <Route path={ACTION.DETAIL} element={<SuspenseWrapper component={<PaymentHistoryDetail />} />} />
          </Route>

          {/* SYSTEM_NOTIFICATION */}
          <Route path={USER_ROUTE_NAME.SYSTEM_NOTIFICATION}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<SystemNotification />} />} />
          </Route>

          <Route path={USER_ROUTE_NAME.QUIT_MEMBERSHIP}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<QuitMemberShip />} />} />
          </Route>

          <Route path={USER_ROUTE_NAME.PROFILE}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<Profile />} />} />
          </Route>

          {/* MEMBER_MANAGEMENT */}
          <Route path={USER_ROUTE_NAME.MEMBER_MANAGEMENT}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<MemberManagement />} />} />
          </Route>

          {/* SETTING_MANAGEMENT */}
          {/* <Route path={USER_ROUTE_NAME.SETTING_MANAGEMENT}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<Settings />} />} />
          </Route> */}

          <Route path={USER_ROUTE_NAME.EDIT_CARD}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<EditCard />} />} />
          </Route>

          {/* CHAT_SUPPORT */}
          <Route path={USER_ROUTE_NAME.CHAT_SUPPORT}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<ChatSupport />} />} />
          </Route>

          {/* EBAY_MANAGEMENT*/}
          <Route path={USER_ROUTE_NAME.EBAY_MANAGEMENT}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<EbayManagement />} />} />
          </Route>
          <Route path={USER_ROUTE_NAME.SHIP_BUYER_REQUEST}>
            <Route path={USER_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<ShipBuyerRequest />} />} />
            <Route path={USER_ROUTE_NAME.REQUEST} element={<SuspenseWrapper component={<Ship2BuyerRequest />} />} />
          </Route>

          <Route path={USER_ROUTE_NAME.NOTIFY_SETTING}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<NotifySetting />} />} />
          </Route>

          <Route path={USER_ROUTE_NAME.PLAN_SETTING}>
            <Route path={USER_ROUTE_NAME.DASHBOARD} element={<SuspenseWrapper component={<PlanSetting />} />} />
            <Route path={USER_ROUTE_NAME.SELECT_PLAN} element={<SuspenseWrapper component={<SelectPlan />} />} />
            <Route path={USER_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<PlanDetail />} />} />
          </Route>

          <Route path={USER_ROUTE_NAME.SUPPORT}>
            <Route path={''} element={<SuspenseWrapper component={<Support />} />} />
          </Route>
          <Route path={USER_ROUTE_NAME.FEDEX}>
            <Route path={''} element={<SuspenseWrapper component={<Fedex />} />} />
          </Route>
          {/* <Route path={USER_ROUTE_NAME.CHATWORK}>
            <Route path={''} element={<SuspenseWrapper component={<ChatWork />} />} />
          </Route> */}

          <Route path={USER_ROUTE_NAME.SHIPPING_LABEL_HISTORY}>
            <Route path={''} element={<SuspenseWrapper component={<ShippingLabelHistory />} />} />
            <Route
              path={USER_ROUTE_NAME.SHIPPING_LABEL_HISTORY_DETAIL}
              element={<SuspenseWrapper component={<ShippingLabelHistory />} />}
            />
          </Route>
          <Route path={USER_ROUTE_NAME.TASK_MANAGEMENT}>
            <Route path={''} element={<SuspenseWrapper component={<TaskManagement />} />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Route>
  </Routes>
);
