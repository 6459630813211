const SvgIncrease = () => (
  <span>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0938 15.25L15.7188 9.625L10.0938 4"
        stroke="white"
        stroke-width="2.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 15.25L9.625 9.625L4 4"
        stroke="white"
        stroke-width="2.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
);

export default SvgIncrease;
